
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  // import HeaderSection from './header.vue'

  import { Resource } from '@/entities/public'

@Component({
  methods: {
    ...mapActions('toolkit', ['load', 'setContent']),
  },
  computed: {
    ...mapGetters('toolkit', ['resource', 'tool']),
  },
  components: {
    HeaderSection: () => null,
    DetailsSection: () => import('./details'),
  },
})
  export default class Toolkit extends Vue {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  @Prop({ type: String, required: true }) model!: string
  @Prop({ type: String, required: true }) slug!: string

  private load: (resource: { model: string, slug: string }) => Promise<Resource>;
  private setContent: (content: any) => Promise<void>;

  declare resource: Resource;
  declare tool: any;

  async created () {
    const { model, slug } = this
    const resource = await this.load({ model, slug })
    this.$emit('loaded', resource.metadata)

    const { value } = this
    if (value) await this.setContent(value)
  }

  get bind () {
    const { resource } = this
    return { resource }
  }

  get on () {
    return {}
  }

  get header () {
    const { bind, tool } = this
    return { ...bind, tool }
  }

  get content () {
    const { bind } = this
    return { ...bind }
  }

  get onHeader () {
    const { on } = this
    return { ...on }
  }

  get onContent () {
    const { on } = this
    return { ...on }
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChanged (newValue: any, oldValue: any) {
    if (newValue !== oldValue) {
      await this.setContent(newValue)
    }
  }
  }
